import React, {  } from 'react';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';
import heroImg from '../images/activity.jpg';
import NavigationButtons from '../components/NavigationButtons';
import Helmet from 'react-helmet';

function Activities({ data }) {
  const { activitiesMessage } = data;
  
  return (
    <div className="welcome-message">
      <Helmet>
        <title> | Activities</title>
      </Helmet>
      <div className="hero-image">
        <img src={heroImg} alt="Activity" />
      </div>
      <h2>My Recommended Activities</h2>
      <p><Markdown>{activitiesMessage}</Markdown></p>
      <NavigationButtons 
        showHouseInfo={!!data.houseManualItems}
        showRestaurants={!!data.restaurantsMessage}
        showActivities={!!data.activitiesMessage}
        showProvisions={!!data.provisionsMessage}
      />
    </div>
  );
}

Activities.propTypes = {
  data: PropTypes.object.isRequired
};
  
export default Activities;