import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import config from '../config';
import { v4 as uuid } from 'uuid';

const isProduction = () => {
  return config.ENVIRONMENT === 'production';
};

const setupLibraries = (bookingId) => {
  let userId = localStorage.getItem('userId');
  if (!userId) {
    userId = uuid();
    localStorage.setItem('userId', userId);
  }

  if (isProduction()) {
    ReactGA.initialize(config.GA_TRACKING_ID);
    mixpanel.init(config.MIXPANEL_PROJECT_TOKEN, {persistence: 'localStorage'});
    mixpanel.identify(userId);
    mixpanel.people.set({
      'Booking ID': bookingId,
    });
    ReactGA.gtag('set', 'user_properties', {
      booking_id: bookingId || 'unknown'
    });
  }
};

const setUserProperties = (guestName, propertyName) => {
  if (isProduction()) {
    mixpanel.people.set({
      'name': guestName,
      'Property Name': propertyName,
    });
  }
};

const trackEvent = (eventName, params = {}) => {
  if (isProduction()) {
    ReactGA.event(eventName, params);
    mixpanel.track(eventName, params);
  }
};

const trackErrorEvent = (error, params) => {
  if (isProduction()) {
    ReactGA.event('error', {
      ...params,
      error
    });
  }
};

const useTrackEvents = () => {
  if (isProduction()) {
    useEffect(() => {
      const handleEvent = (event) => {
        const { target } = event;
        if (target.tagName.toLowerCase() !== 'a') {
          return;
        }
        const eventProps = {
          target: target.href,
        };

        trackEvent('External Link Click', eventProps);
      };

      window.addEventListener('click', handleEvent);

      return () => {
        window.removeEventListener('click', handleEvent);
      };
    }, []);
  }
};

const useTrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    if (isProduction()) {
      mixpanel.track('Page View');
    }
  }, [location]);
};

export { setupLibraries, trackEvent, setUserProperties, trackErrorEvent, useTrackEvents, useTrackPageViews };
