import React from 'react';
import { useLocation } from 'react-router-dom';
import useNavigateWithTracking from '../shared/navigateWithTracking';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faUtensils, faBicycle, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

function NavigationButtons({ showHouseInfo, showRestaurants, showActivities, showProvisions }) {
  const location = useLocation();
  const navigateWithTracking = useNavigateWithTracking();
  const { search } = useLocation();

  const isRouteActive = (route) => {
    return location.pathname === route;
  };

  return (
    <div className="navigation-buttons">
      {showHouseInfo &&
        <button onClick={() => { navigateWithTracking(`/house-info${search}` ) }} className={isRouteActive('/house-info') ? 'active' : ''}>
          <span className="navigation-button-wrapper"><FontAwesomeIcon icon={faBook} /></span>
          House Info
        </button>
      }
      {showRestaurants &&
        <button onClick={() => { navigateWithTracking(`/restaurants${search}` ) }} className={isRouteActive('/restaurants') ? 'active' : ''}>
        <span className="navigation-button-wrapper"><FontAwesomeIcon icon={faUtensils} /></span>
          Restaurants
        </button>
      }
      {showActivities &&
        <button onClick={() => { navigateWithTracking(`/activities${search}` ) }} className={isRouteActive('/activities') ? 'active' : ''}>
        <span className="navigation-button-wrapper"><FontAwesomeIcon icon={faBicycle} /></span>
          Activities
        </button>
      }
      {showProvisions &&
        <button onClick={() => { navigateWithTracking(`/provisions${search}` ) }} className={isRouteActive('/provisions') ? 'active' : ''}>
        <span className="navigation-button-wrapper"><FontAwesomeIcon icon={faShoppingCart} /></span>
          Provisions
        </button>
  }
    </div>
  );
}

NavigationButtons.propTypes = {
  showHouseInfo: PropTypes.bool,
  showRestaurants: PropTypes.bool,
  showActivities: PropTypes.bool,
  showProvisions: PropTypes.bool
};

export default NavigationButtons;