import config from '../config';
import './SurveyForm.css';
import React, { useState } from 'react';
import useNavigateWithTracking from '../shared/navigateWithTracking';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { trackErrorEvent, trackEvent } from '../shared/trackingManager';

function LoadingOverlay() {
  return (
    <div className="loading-overlay">
      <div className="loader">Sending...</div>
    </div>
  );
}

function SurveyForm({ guestInfo, metadata }) {
  const [hidePetsQuestion] = useState(metadata.hidePetsQuestion || false);
  const [firstName] = useState(`${guestInfo.firstName}` || '');
  const [lastName] = useState(`${guestInfo.lastName}` || '');
  const [guests, setGuests] = useState(guestInfo.guests || []);
  const [email, setEmail] = useState(guestInfo.email || '');
  const [phoneNumber, setPhoneNumber] = useState(guestInfo.phoneNumber || '');
  const [textMessageConsent, setTextMessageConsent] = useState(guestInfo.textMessageConsent || false);
  const [budget, setBudget] = useState(guestInfo.budget || '');
  const [petType, setPetType] = useState(guestInfo.petType || '');
  const [prefersGoingOut, setPrefersGoingOut] = useState(guestInfo.prefersGoingOut || '');
  const [purpose, setPurpose] = useState(guestInfo.purpose || '');
  const [specialOccasion, setSpecialOccasion] = useState(guestInfo.specialOccasion || '');
  const [extraInfo, setExtraInfo] = useState(guestInfo.extraInfo || '');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const bookingId = localStorage.getItem('bookingId');
  const navigateWithTracking = useNavigateWithTracking();

  const handleGuestChange = (index, event) => {
    const updatedGuests = [...guests];
    updatedGuests[index][event.target.name] = event.target.value;
    setGuests(updatedGuests);
  };
  
  const addGuest = () => {
    setGuests([...guests, { name: '', age: 'adult' }]);
  };
  
  const removeGuest = (index) => {
    const updatedGuests = [...guests];
    updatedGuests.splice(index, 1);
    setGuests(updatedGuests);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
  
    // Prepare the form data
    const formData = {
      bookingId,
      guests,
      email,
      phoneNumber,
      textMessageConsent,
      budget,
      petType,
      prefersGoingOut,
      purpose,
      specialOccasion,
      extraInfo
    };
  
    // POST request to /guestSurvey endpoint
    try {
      const response = await fetch(`${config.API_BASE_URL}/guestSurvey`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const searchParams = new URLSearchParams(location.search);
        const queryString = searchParams.toString();

        trackEvent('Survey Submitted', formData);
        // Redirect on successful submission
        navigateWithTracking(`/survey-success?${queryString}`, { state: { 
          message: 'Survey submitted successfully! Your host will be in touch with personalized recommendations soon!' 
        } });
      } else {
        trackErrorEvent(response.status);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setIsSubmitting(false);

    } catch (error) {
      console.error('Error submitting survey:', error);
      trackErrorEvent(error);
    }
  };
  
  return (
    <div className="survey">
      {isSubmitting && <LoadingOverlay />}
      <p className="survey-intro">Hey there!</p>
      <p className="survey-intro">We’d love to make your stay extra special. Share your preferences with us so we can personalize your experience. Remember, all fields are totally optional!</p>

      <form onSubmit={handleSubmit} className="survey-form">

        <label>Guests</label>
        <div className="input-container">
          <input
            type="text"
            name="firstName"
            disabled="true"
            value={firstName}/>
          <input
            type="text"
            name="lastName"
            disabled="true"
            value={lastName}/>
        </div>
        {guests.map((guest, index) => (
          <div className="input-container" key={index}>
            <input
              type="text"
              name="name"
              placeholder="Guest Name"
              value={guest.name}
              onChange={(e) => handleGuestChange(index, e)}/>
            <select name="age" value={guest.age} onChange={(e) => handleGuestChange(index, e)}>
              <option value="adult">Adult (18+)</option>
              <option value="older_child">Young Adult (10-17)</option>
              <option value="younger_child">Child (3-9)</option>
              <option value="infant">Infant (0-2)</option>
            </select>
            <button type="button" onClick={() => removeGuest(index)} className="remove-guest-button">
                <FontAwesomeIcon icon={faMinus} />
              </button>
          </div>
        ))}

        <button type="button" onClick={addGuest} className="add-guest-button form-button">Add Another Guest</button>

        <label>Email</label>
        <p className="disclaimer">We’ll use this to send you my recommendations, look for an email from Welcome Compass</p>
        <div className="input-container">
          <input
            type="text"
            name="name"
            placeholder=""
            value={email}
            onChange={(e) => setEmail(e.target.value)}/>
        </div>

        <label>Phone Number</label>
        <p className="disclaimer">We will not send marketing messages, but may use your number for reservation reminders.</p>
        <div className="input-container">
          <input
            type="tel"
            name="phoneNumber"
            placeholder="Enter your phone number"
            value={phoneNumber}
            pattern="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
            title="Phone number must be in the format: 123-456-7890 or similar formats."
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className="checkbox-group">
          <label className="custom-checkbox">
            <input
              type="checkbox"
              checked={textMessageConsent}
              onChange={(e) => setTextMessageConsent(e.target.checked)}
            />
            <span className="checkmark"></span>
          </label>
          <label className="label-text" onClick={() => setTextMessageConsent(!textMessageConsent)}>
            I consent to use my phone number for reservation reminders.
          </label>
        </div>

        
        <label>Budget</label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              value="low"
              checked={budget === 'low'}
              onChange={(e) => setBudget(e.target.value)}
            />
            <span></span>
            <span className="label-text">💰</span>
          </label>
          <label>
            <input
              type="radio"
              value="medium"
              checked={budget === 'medium'}
              onChange={(e) => setBudget(e.target.value)}
            />
            <span></span>
            <span className="label-text">💰💰</span>
          </label>
          <label>
            <input
              type="radio"
              value="high"
              checked={budget === 'high'}
              onChange={(e) => setBudget(e.target.value)}
            />
            <span></span>
            <span className="label-text">💰💰💰</span>
          </label>
        </div>

        {!hidePetsQuestion &&
          <div>
            <label htmlFor="petType">Pets?</label>
            <select name="petType" value={petType} onChange={(e) => setPetType(e.target.value)}>
              <option value="">No Pets</option>
              <option value="small_dog">Small Dog</option>
              <option value="large_dog">Large Dog</option>
              <option value="cat">Cat</option>
              <option value="multiple">Multiple</option>
            </select>
          </div>
        }

        <label htmlFor="prefersGoingOut">Do You Prefer Going Out or Staying In?</label>
        <select name="prefersGoingOut" value={prefersGoingOut} onChange={(e) => setPrefersGoingOut(e.target.value)}>
          <option value="">Both</option>
          <option value="true">Going out</option>
          <option value="false">Staying in</option>
        </select>

        <label htmlFor="purpose">Purpose of Trip</label>
        <select name="purpose" value={purpose} onChange={(e) => setPurpose(e.target.value)}>
          <option value="">No reason, just a vacay!</option>
          <option value="romantic_getaway">Romantic getaway</option>
          <option value="friends_weekend">Friends weekend</option>
          <option value="explore_new_area">Explore new area</option>
          <option value="family_vacation">Family vacation</option>
        </select>

        <label htmlFor="specialOccasion">Special Occasion</label>
        <select name="specialOccasion" value={specialOccasion} onChange={(e) => setSpecialOccasion(e.target.value)}>
          <option value="">None</option>
          <option value="birthday">Birthday</option>
          <option value="bachelorette_party">Bachelorette Party</option>
          <option value="bachelor_party">Bachelor Party</option>
          <option value="anniversary">Anniversary</option>
        </select>

        <label htmlFor="extraInfo">Any other preferences or interests to add? We want to recommend the right things!</label>
        <textarea
          id="extraInfo"
          value={extraInfo}
          placeholder="I love history and I’m coming for the best coffee in town!"
          onChange={(e) => setExtraInfo(e.target.value)}
        />

        <button type="submit" className="form-button">Submit Survey</button>

      </form>
    </div>
  );
}

SurveyForm.propTypes = {
  guestInfo: PropTypes.object,
  metadata: PropTypes.object
};
      
export default SurveyForm;
