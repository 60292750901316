import React, { useState, useCallback } from 'react';
import useNavigateWithTracking from '../shared/navigateWithTracking';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconMapping } from '../shared/icons';
import NavigationButtons from '../components/NavigationButtons';
import Helmet from 'react-helmet';
import { trackEvent } from '../shared/trackingManager';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

function HouseInfo({ data }) {
  const [searchTerm, setSearchTerm] = useState('');
  const navigateWithTracking = useNavigateWithTracking();
  const {search} = useLocation();

  const filteredItems = searchTerm.trim()
  ? data.houseManualItems.filter(item =>
      searchTerm.toLowerCase().split(' ').every(word =>
        item.type.toLowerCase().includes(word) ||
        item.title.toLowerCase().includes(word) ||
        item.content.toLowerCase().includes(word)
      )
    )
  : data.houseManualItems;

  function debounce(func, wait) {
    let timeout;
  
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
  
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const debouncedTrackSearchTerm = useCallback(debounce((searchTerm) => {
    trackEvent('Search House Info', { searchTerm });
  }, 1000), []);
  
  return (
    <div>
      <Helmet>
        <title> | House Information</title>
      </Helmet>
      <div className="search-box">
      <FontAwesomeIcon icon={faSearch} />
        <input
          type="text"
          placeholder="e.g. remote, wifi password, etc."
          value={searchTerm}
          onChange={e => {
            setSearchTerm(e.target.value);
            debouncedTrackSearchTerm(e.target.value);
          }}
        />
      </div>
      <div className="house-info-container">
      {filteredItems.map((item, index) => (
        <div 
          key={index} 
          className="house-info-item"
          onClick={() => navigateWithTracking(`/house-info/${item.id}${search}`)}
        >
          <FontAwesomeIcon icon={iconMapping[item.type]} size="2x" />
          <h2>{item.title}</h2>
        </div>
      ))}
      </div>
      <NavigationButtons 
        showHouseInfo={!!data.houseManualItems}
        showRestaurants={!!data.restaurantsMessage}
        showActivities={!!data.activitiesMessage}
        showProvisions={!!data.provisionsMessage}
      />
    </div>
  );
}

HouseInfo.propTypes = {
  data: PropTypes.array.isRequired
};
  
export default HouseInfo;