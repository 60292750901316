import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import useNavigateWithTracking from '../shared/navigateWithTracking';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faComment } from '@fortawesome/free-solid-svg-icons';

function TopBar({ arrivalDate, textOverride, showAskButton = true }) {
  const location = useLocation();
  const {search} = useLocation();
  const navigateWithTracking = useNavigateWithTracking();
  const isDemo = location.pathname === '/demo';
  const hideHomeButton = location.pathname === '/' || location.pathname === '/survey' || location.pathname === '/survey-success' || location.pathname === '/demo';
  const hideAskButton = !showAskButton || location.pathname === '/survey' || location.pathname === '/survey-success' || location.pathname === '/demo';
  
  const [showBadge, setShowBadge] = useState(false);

  // Demo Banner
  const urlParams = new URLSearchParams(window.location.search);
  const showDemoBanner = urlParams.has('demo') && urlParams.get('demo') === 'true' && location.pathname !== '/survey' && location.pathname !== '/survey-success' && location.pathname !== '/demo';

  useEffect(() => {
    // Check if the badge has already been shown by querying localStorage
    if (localStorage.getItem('badgeShown') !== 'true') {
      setShowBadge(true); // Show the badge if not shown before
    }
  }, []);

  const handleQuestionClick = () => {
    setShowBadge(false); // Hide the badge on click
    localStorage.setItem('badgeShown', 'true'); // Mark the badge as shown in localStorage
    navigateWithTracking(`/ask${search}`); // Assuming 'search' is defined elsewhere
  };

  const calculateCountdown = (arrivalDateStr) => {
    const currentDateTime = new Date();
    const currentDateStr = currentDateTime.toISOString().split('T')[0];
    const currentDate = new Date(currentDateStr);
    const arrivalDateTime = new Date(arrivalDateStr + 'T00:00:00Z'); // Assume arrivalDateStr is in 'YYYY-MM-DD' format
    const delta = arrivalDateTime - currentDate;
    const daysUntilArrival = Math.ceil(delta / (1000 * 60 * 60 * 24));
    
    if (textOverride) {
      return textOverride
    } else if (daysUntilArrival === 1) {
      return `${daysUntilArrival} day until your arrival`;
    } else if (daysUntilArrival > 1) {
      return `${daysUntilArrival} days until your arrival`;
    } else if (daysUntilArrival === 0) {
      return "Arrival Day!";
    } else {
      return "Enjoy your stay";
    }
  };

  return (
    <div className="top-bar-wrapper">
      <div className="top-bar">
        {!hideHomeButton && (
          <button
            onClick={() => navigateWithTracking(`/${search}`)} 
            className="back-button">
            <FontAwesomeIcon icon={faHouse} />
          </button>
        )}
        <div className="arrival-info">
          {isDemo ? "Welcome To The Demo" : calculateCountdown(arrivalDate)}
        </div>
          {!hideAskButton && (
          <button onClick={handleQuestionClick} className="question-button">
            {showBadge && <div className="notification-badge"> </div>}
            <FontAwesomeIcon icon={faComment} />
          </button>
        )}
      </div>
      {showDemoBanner &&
        <a href="https://www.welcomecompass.com/get-started" target="_blank" className="demo-banner" rel="noreferrer">
          Click here to see a demo of your property<br/><strong>Completely Free!</strong>
        </a>
      }
    </div>
  );
}

TopBar.propTypes = {
  arrivalDate: PropTypes.string.isRequired,
  textOverride: PropTypes.string,
  showAskButton: PropTypes.bool
};
  
export default TopBar;
