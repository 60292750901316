import React, {  } from 'react';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';
import heroImg from '../images/restaurant.jpg';
import NavigationButtons from '../components/NavigationButtons';
import Helmet from 'react-helmet';

function Restaurants({ data }) {
  const { restaurantsMessage } = data;
  
  return (
    <div className="welcome-message">
      <Helmet>
        <title> | Restaurants</title>
      </Helmet>
      <div className="hero-image">
        <img src={heroImg} alt="Restaurant" />
      </div>
      <h2>My Restaurant Recommendations</h2>
      <p><Markdown>{restaurantsMessage}</Markdown></p>
      <NavigationButtons 
        showHouseInfo={!!data.houseManualItems}
        showRestaurants={!!data.restaurantsMessage}
        showActivities={!!data.activitiesMessage}
        showProvisions={!!data.provisionsMessage}
      />
    </div>
  );
}

Restaurants.propTypes = {
  data: PropTypes.object.isRequired
};
  
export default Restaurants;
